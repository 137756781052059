@import url('https://fonts.googleapis.com/css?family=Fredoka+One');
/* https://res.cloudinary.com/dgps1radv/image/upload/c_fill,w_200/mto5squ8abpmd02zymev */
.aleppokitchen {  
  .home {
    background: rgba(25,25,25,1);
    /* background: none; */
    color: white;
  }
  a.product-link {
    background-color: #f79113;
  }
  img.nav-bar-logo {
    // display: block;
    padding-left: 0.8em;
    padding-bottom: 0.2em;
  }
  .cart.minimized {
    background-color: #f29e03;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='46' height='46' fill-opacity='0.6' fill='%23ffa61d'/%3E%3C/svg%3E");
    // padding-bottom: 0;
    border: none;
    /* background: black; */
    border-bottom: 2px solid rgba(255,255,255,0.8);
    /* background: repeating-linear-gradient(
    90deg,
    #dd0016,
    #dd0016 40px,
    #eee 40px,
    #eee 80px
    ); */
  }

  .order-benefits {
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    /* max-height: 50vh; */
    // background: url(https://thumbs.dreamstime.com/z/various-mediterranean-dishes-bread-table-top-down-view-freshly-prepared-delicious-varieties-wooden-80885406.jpg);
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/pcrxx2rryzfcubhev6ut);
    background-size: cover;
    background-position: center 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .order-benefits .text {
    line-height: 1.5em;
    font-family: 'Fredoka One', sans-serif;
    /* color: black; */
    color: white;
    background: rgba(0,0,0,0.8);
    padding: 0.5em 0.8em;
    strong {
      font-size: 1.5em;
      line-height: 2em;
    }
    a {
      color:  #f29e03;
    }
  }

  a.checkout-button.button {
    background: #e6001c;

    font-weight: 600;
    box-shadow: none;
    border: 2px solid #eee;

  }

  /* .category__header {
  font-family: cursive;

} */
.category-name .text {
  font-family: 'Fredoka One', sans-serif;
  font-weight: 400;
  font-size: 1.5em;
  background: #f79113;
  color: white;

}
.category-name .text::before {
  content: "• "
}
.category-name .text::after {
  content: " •"
}
}
